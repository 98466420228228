'use client';

import * as React from 'react';
import { useRouter, usePathname, redirect } from 'next/navigation';
import { paths } from '@/app/constants/paths';
import { logger } from '@/app/lib/logger/default-logger';
import { useUser } from '@/app/hooks/useUser';
import { useSnackbar } from '@/app/contexts/snack-bar-provider';
import { clearUserCookies, deleteExpiredToken } from '@/app/helpers/cookiesMethod';
export interface AuthGuardProps {
    children: React.ReactNode;
}

export function AuthGuard({ children }: AuthGuardProps): React.JSX.Element | null {
    const router = useRouter();
    const { user, auth, error, isLoading, updateDataFromCookiesForProvider } = useUser();
    const [isChecking, setIsChecking] = React.useState<boolean>(true);
    const { addSnackbar } = useSnackbar();
    const [initialLoad, setInitialLoad] = React.useState<boolean>(true);
    const pathname = usePathname();
    const checkPermissions = async (): Promise<void> => {
        if (initialLoad || isLoading || error) {
            addSnackbar({
                message: error || 'Some thing went wrong',
                severity: 'error',
                autoHideDuration: 3000,
                vertical: 'bottom',
                horizontal: 'center',
                type: 'toast',
            });
            setIsChecking(false);
            return;
        }
        if (
            !user?.id
            //check accessToken later
        ) {
            clearUserCookies();
            deleteExpiredToken();
            router.replace(paths.auth.signIn);
            return
        } else if (user?.id && auth?.isRegistered) {
            if (pathname === '/login') {
                router.replace(paths.home);
            }
        }
        setIsChecking(false);
    };

    React.useEffect(() => {
        if (!initialLoad) {
            checkPermissions().catch((e) => {
                logger.error('Failed to check permissions:', e);
            });
        }
    }, [user, isLoading, initialLoad]);

    React.useEffect(() => {
        if (updateDataFromCookiesForProvider) {
            updateDataFromCookiesForProvider().then(() => {
                setInitialLoad(false);
            });
        }
    }, []);

    return <React.Fragment>{children}</React.Fragment>;
}
