'use client';
import { AuthGuard } from '@/app/components/Auth/AuthGuard';
import dynamic from 'next/dynamic';
const Login = dynamic(() => import('@/app/components/Auth/Login'), { ssr: false });
export default function login() {
    return (
        <AuthGuard>
            <Login />
        </AuthGuard>
    );
}
